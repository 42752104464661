import { ThemeProvider } from 'styled-components';
import { CookiesProvider } from 'react-cookie';

import { LiveChatLoaderProvider } from 'react-live-chat-loader';
import { theme } from 'styled/theme';

import { CookiePreferencesProvider } from 'components/CookiePreferences/CookiePreferencesProvider';

import type { FC, ReactNode } from 'react';

//  default is our production app-id
const intercomAppId = 'tv6jsyee';

/** These are providers, expected to used in `gatsby-browser` and `gatsby-ssr` to wrap all of Gatsby. */
export const Providers: FC<{ children: ReactNode }> = ({ children }) => (
  <CookiesProvider>
    <LiveChatLoaderProvider providerKey={intercomAppId} provider="intercom">
      <ThemeProvider theme={theme}>
        <CookiePreferencesProvider>{children}</CookiePreferencesProvider>
      </ThemeProvider>
    </LiveChatLoaderProvider>
  </CookiesProvider>
);
